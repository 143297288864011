import { useEffect, useState } from 'react';
import { Menu, Text, Group, Image, createStyles, Button, Center } from '@mantine/core';
import { API } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import chevronDown from './chevronDown.svg';

const useStyles = createStyles((theme) => ({
  
    link: {
      display: 'block',
      lineHeight: 1,
      padding: '8px 12px',
      borderRadius: theme.radius.sm,
      textDecoration: 'none',
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
      fontSize: theme.fontSizes.sm,
      fontWeight: 500,
  
      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      },
    },
  
  }));

function StatsInMenu({user, userid, username}) {
    const { classes } = useStyles();
    const [userStatsTableinLayout, setUserStatsTableinLayout] = useState({
        cumulativePromptTokens: 0,
        cumulativeCompletionTokens: 0,
        cumulativePromptWords: 0,
        cumulativeCompletionWords: 0,
        wordsRemaining: 100000,
      });
      const [opened, setOpened] = useState(false);
      const { route, signOut } = useAuthenticator((context) => [
        context.route,
        context.signOut,
      ]);
      const navigate = useNavigate();

    function logOut() {
      signOut();
      navigate('/');
    };

    useEffect(() => {
      async function getStatsByUser() {
        try {
          var lastStats = await API.graphql({
            query: queries.statsByUser,
            variables: {
              userId: userid,
              sortDirection: 'DESC',
              limit: 1
            }
          });
          if (lastStats.data.statsByUser.items.length > 0) {
            setUserStatsTableinLayout(lastStats.data.statsByUser.items[0]);
          };
          console.log("Userstats retrieved from the cloud.")
        } catch (error) {
          console.log("Error retrieving user stats", error);
        }
      }
      if (opened && user) {
        getStatsByUser();
      };
    }, [opened, user, userid]);
    //console.log("Userstats in StatsInMenu", userStatsTableinLayout);

    return(
      <Menu opened={opened} onChange={setOpened}>
        <Menu.Target>
          <Group spacing="xs">
            <Text className={classes.link}>Hello, {username}</Text>{' '}
            <Image src={chevronDown} height={15} width={15} alt="chevron down" />
            </Group>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Usage Statistics</Menu.Label>
          <Menu.Item>Cumulative Prompt Words: {userStatsTableinLayout.cumulativePromptWords.toLocaleString("en-US")}</Menu.Item>
          <Menu.Item>Cumulative Completion Words: {userStatsTableinLayout.cumulativeCompletionWords.toLocaleString("en-US")}</Menu.Item>
          <Menu.Item>Total Words: {(userStatsTableinLayout.cumulativePromptWords + userStatsTableinLayout.cumulativeCompletionWords).toLocaleString("en-US")}</Menu.Item>
          <Menu.Item>Words Remaining: {userStatsTableinLayout.wordsRemaining.toLocaleString("en-US")}</Menu.Item>
          <Menu.Divider />
          {route === 'authenticated' ? ( 
            <Center>
              <Button 
                variant='outline'
                size='sm'
                color='gray'
                onClick={() => logOut()} sx={{ height: 30 }}>
                Logout
              </Button>
            </Center>
            ) : (
              null
            )   
          }
        </Menu.Dropdown> 
      </Menu>
    )
};

export default StatsInMenu;