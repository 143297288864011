import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import RequireAuth from './components/RequireAuth';
import LandingPage from './components/LandingPageComponent';
import { Login } from './components/LoginComponent';
import MainPage from './components/MainPageComponent';
import { Layout } from './components/Layout';
import ShowHistory from './components/HistoryComponent';
import Examples from './components/ExampleComponent';
import './components/styles.css';
import FAQ from './components/FAQComponent';

function MyRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route index element={<LandingPage />} />
          <Route path='/landing' element={<LandingPage />} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='/examples' element={<Examples />} />
          <Route
            path="/main"
            element={
              <RequireAuth>
                <MainPage />
              </RequireAuth>
            }
          />
          <Route
            path="/history"
            element={
              <RequireAuth>
                <ShowHistory />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
        </Route>
      </Routes>
    </Router>
  )
};

function App() {
  return(
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default App;
