import { Container, Title, Text, Image, createStyles,
    Table, Accordion } from "@mantine/core";
import fileText from './fileText.svg';
import corporateAgreements from './examples/corporateAgreementExamples.json';
import employmentAgreements from './examples/employmentAgreementExamples.json';
import prenupAgreements from './examples/prenupExamples.json';
import governmentContracts from './examples/governmentContractExamples.json';


const useStyles = createStyles((theme) => ({
    wrapper: {
      paddingTop: theme.spacing.xl * 2,
      paddingBottom: theme.spacing.xl * 2,
      minHeight: 650,
    },
  
    title: {
      marginBottom: theme.spacing.xl * 1.5,
    },

    control: {
        fontSize: theme.fontSizes.lg,
        fontWeight: 500,
    },
  
    item: {
      borderRadius: theme.radius.md,
      marginBottom: theme.spacing.lg,
      minHeight: 70,
  
      border: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    },

    iconItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.radius.md,
        //height: 90,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        transition: 'box-shadow 150ms ease, transform 100ms ease',
    
        '&:hover': {
          boxShadow: `${theme.shadows.md} !important`,
          transform: 'scale(1.05)',
        },
      },

  }));

function Examples() {
    const { classes } = useStyles();
    //const getColor = (color) => theme.colors[color][theme.colorScheme === 'dark' ? 5 : 7];

    function TableTopic({title, file}) {

        const rows = file.map((row, index) => (
            <tr key={index}>
                <td>
                    <Text>{row.legal_text}</Text>
                </td>
                <td>
                    <Text>{row.plain_english}</Text>
                </td>
            </tr>
        ));

        return (
            <Table striped>
                <thead>
                    <tr>
                        <th>Legal Text</th>
                        <th>Plain English</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </Table>
        );
    };


    
  return (
    <div>
    <Container>
            <Title>Examples</Title>
            <Text>
                Legalese turned into plain English.
            </Text>
    </Container>

    <Container className={classes.wrapper}>
        <Accordion variant="contained">
            <Accordion.Item className={classes.item} value="corporateAgreements" sx={{backgroundColor: '#FFA8A8'}}>
                <Accordion.Control icon={<Image src={fileText} height={50} width={50} />}>
                    <Text className={classes.control}>Corporate Agreements</Text>
                </Accordion.Control>
                <Accordion.Panel>
                    <TableTopic title="Corporate Agreements" file={corporateAgreements} />
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="employmentAgreements" sx={{backgroundColor: '#C0EB75'}}>
                <Accordion.Control icon={<Image src={fileText} height={50} width={50} />}>
                    <Text className={classes.control}>Employment Agreements</Text>
                </Accordion.Control>
                <Accordion.Panel>
                    <TableTopic title="Employment Agreements" file={employmentAgreements} />
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="prenupAgreements" sx={{backgroundColor: '#74C0FC'}}>
                <Accordion.Control icon={<Image src={fileText} height={50} width={50} />}>
                    <Text className={classes.control}>Prenuptial Agreements</Text>
                </Accordion.Control>
                <Accordion.Panel>
                    <TableTopic title="Prenuptial Agreements" file={prenupAgreements} />
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="governmentContracts" sx={{backgroundColor: '#B197FC'}}>
                <Accordion.Control icon={<Image src={fileText} height={50} width={50} />}>
                    <Text className={classes.control}>Government Contracts</Text>
                </Accordion.Control>
                <Accordion.Panel>
                    <TableTopic title="Government Contracts" file={governmentContracts} />
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    </Container>
    </div>
  );
};

export default Examples;