import React, { useState, useEffect } from 'react';
import { createStyles, Textarea, Button, Text, Container, Grid,
 Box, Title, Image, Center, Loader, Group } from '@mantine/core';
import '@aws-amplify/ui-react/styles.css';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { API, graphqlOperation } from 'aws-amplify';
import * as myappQueries from '../mygraphql/queries';
import * as mutations from '../mygraphql/mutations';
import { v4 as uuidv4 } from 'uuid';
import { prepareText, isJsonString } from './checkInputText';

import { SampleLegalLingo } from './SampleLegalLingo';
import ShowHistory from './HistoryComponent';
import { TipsModal } from './TipsModalComponent';
import TokenCount from './TokenCountComponent';
import Feedback from './FeedbackComponent';
import { CardWithStats } from './UserStatsComponent';
import clockImage from './counter-clockwise-clock.svg';
import lightbulb from './lightbulb.svg';
import cross from './cross.svg';
import info from './infoIcon.svg';


const useStyles = createStyles((theme) => ({
    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: theme.spacing.xl * 4,
      paddingBottom: theme.spacing.xl * 4,
    },
  
    content: {
      maxWidth: 480,
      marginRight: theme.spacing.xl * 3,
  
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        marginRight: 0,
      },
    },
  
    title: {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      //fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontSize: 40,
      lineHeight: 1.0,
      padding: theme.spacing.lg * 1/2,
      //fontWeight: 900,
  
      [theme.fn.smallerThan('sm')]: {
        fontSize: 20,
      },
    },
  
    control: {
      [theme.fn.smallerThan('xs')]: {
        flex: 1,
      },
    },

    image: {
      flex: 1,
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },
  
    highlight: {
      position: 'relative',
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      borderRadius: theme.radius.sm,
      padding: '4px 12px',
    },

    iconImage: {
      maxWidth: 48,
      maxHeight: 48,
      marginRight: 30,

      [theme.fn.smallerThan("md")]: {
        maxWidth: 32,
        maxHeight: 32,
        marginRight: 50,
      },

      '&:hover': {
        cursor: 'pointer',
        },
      },

    feedbackImage: {
      maxWidth: 15,
      maxHeight: 15,
      marginRight: 7,
      '&:hover': {
        cursor: 'pointer',
        },
    },

    text: {
      fontSize: theme.fontSizes.sm - 1,
    },
  }));

function MainPage() {
  const { classes } = useStyles();
  const { user }= useAuthenticator(context => [context.user]);
  const userid = user.username;
  const { route } = useAuthenticator(context => [context.route]);
  var Filter = require('bad-words');

  //variables for the history component
  const [legal, setLegal] = useState('');
  const [answer, setAnswer] = useState('');
  const [answerResponseId, setAnswerResponseId] = useState('');
  const [promptTokens, setPromptTokens] = useState(0);
  const [completionTokens, setCompletionTokens] = useState(0);
  const [totalTokens, setTotalTokens] = useState(0);
  const [historyTable, setHistoryTable] = useState([]);
  const [refreshHistory, setRefreshHistory] = useState(0);
  const [displayExample, setDisplayExample] = useState(false);
  const [displayHistory, setDisplayHistory] = useState(false);
  const [displayTipsModal, setDisplayTipsModal] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(false);

  //variables for userStats component
  const [userStatsTable, setUserStatsTable] = useState([{
    cumulativePromptTokens: 0,
    cumulativeCompletionTokens: 0,
    cumulativePromptWords: 0,
    cumulativeCompletionWords: 0,
    wordsRemaining: 100000,
    contentFilterOne: 0,
    contentFilterTwo: 0,
  }]);
  const [refreshUserStats, setRefreshUserStats] = useState(0);
  //const [wordsPurchased, setWordsPurchased] = useState();
  const [wordsRemaining, setWordsRemaining] = useState(100000);
  //const [amountPaid, setAmountPaid] = useState(0);


  const body = {
    user: userid,
    legal: prepareText(legal)
  };

  function countWordsInText(text) {
    var words = '';
    if (text.length > 0) {
      words = text.trim().split(" ");
    }
    return words.length;
  };

  function checkBadWords(text) {
    var filter = new Filter();
    if (filter.isProfane(text)) {
      return true;
    } else {
      return false;
    }
  };

  //console.log('User is: ', userid)
  useEffect(() =>{
    async function getStatsByUser() {
      try {
        var lastStats = await API.graphql({
          query: myappQueries.statsByUser,
          variables: {
            userId: userid,
            sortDirection: 'DESC',
            limit: 1
          }
        });
        if (lastStats.data.statsByUser.items.length > 0) {
          setUserStatsTable(lastStats.data.statsByUser.items[0]);
        };
      } catch (error) {
        console.log("Error retrieving user stats", error);
      }
    };
    getStatsByUser();
  }, [userid, refreshUserStats]);

  useEffect(() => {
    async function getHistoryByUser() {
      try {
        var history = await API.graphql({
          query: myappQueries.historiesByUser,
          variables: {
            userId: userid,
            sortDirection: 'DESC'
          }
        });
        //console.log("history", history);
        setHistoryTable(history.data.historiesByUser.items);
      } catch (error) {
        console.log("Error retrieving history", error);
      }
    };
    getHistoryByUser();
  }, [userid, refreshHistory, answer]);

  async function updateUserStats(latestSubmission) {
    //first, get the latest stats
    let lastUserStat //initialize the variable. Either last cloud value or zeros
    try {
      var lastUserStatInCloud = await API.graphql({
        query: myappQueries.statsByUser,
        variables: {
          userId: userid,
          sortDirection: 'DESC',
          limit: 1
        }
      });
      if (lastUserStatInCloud.data.statsByUser.items.length > 0) {
        setUserStatsTable(lastUserStatInCloud.data.statsByUser.items[0]);
        lastUserStat = lastUserStatInCloud.data.statsByUser.items[0];
      } else {
        lastUserStat = userStatsTable[0]; //zeros
      };
      //console.log("lastUserStat: ", lastUserStat);
    } catch (error) {
      console.log("Error retrieving user stats", error);
    };

    if (lastUserStat.contentFilterOne === null) {
      lastUserStat.contentFilterOne = 0;
    };
    if (lastUserStat.contentFilterTwo === null) {
      lastUserStat.contentFilterTwo = 0;
    };
    if (latestSubmission.contentFilter === 1) {
      latestSubmission.contentFilterOne = 1;
    } else if (latestSubmission.contentFilter === 2) {
      latestSubmission.contentFilterTwo = 1;
    } else {
      latestSubmission.contentFilterOne = 0;
      latestSubmission.contentFilterTwo = 0;
    };

    //then, update the stats
    let updatedUserStats = {
      id: uuidv4(),
      userId: userid,
      cumulativePromptTokens: lastUserStat.cumulativePromptTokens + latestSubmission.promptTokens,
      cumulativeCompletionTokens: lastUserStat.cumulativeCompletionTokens + latestSubmission.completionTokens,
      cumulativePromptWords: lastUserStat.cumulativePromptWords + latestSubmission.promptWords,
      cumulativeCompletionWords: lastUserStat.cumulativeCompletionWords + latestSubmission.completionWords,
      wordsRemaining: lastUserStat.wordsRemaining - (latestSubmission.promptWords + latestSubmission.completionWords),
      contentFilterOne: lastUserStat.contentFilterOne + latestSubmission.contentFilterOne,
      contentFilterTwo: lastUserStat.contentFilterTwo + latestSubmission.contentFilterTwo,
    };
    //console.log("updatedUserStats: ", updatedUserStats);
    try {
      API.graphql({
        query: mutations.createUserStats,
        variables: {
          input: updatedUserStats
        }
      });
      //console.log("User stats have been updated");
      setRefreshUserStats(refreshUserStats + 1);
    } catch (error) {
      console.log("Error creating user stats", error);
    }
  };

  async function handleSaveHistory(data) {
    const JSONdata = JSON.parse(data.body);
    let newHistoryDetails = {
      id: JSONdata.id,
      userId: userid,
      legal_text: legal,
      plain_english: JSONdata.message,
      promptTokens: JSONdata.usage.prompt_tokens,
      completionTokens: JSONdata.usage.completion_tokens,
      promptWords: countWordsInText(legal),
      completionWords: countWordsInText(JSONdata.message),
      contentFilter: parseInt(JSONdata.filter_output),
    };
    //console.log('newHistoryDetails: ', newHistoryDetails);
    try {
      await API.graphql({
        query: mutations.createHistory,
        variables: {input: newHistoryDetails}
      });
      console.log('History added successfully.');
      setRefreshHistory(refreshHistory + 1);
      //console.log('refreshHistory count: ', refreshHistory);
    } catch (error) {
      console.log('Error saving post', error)
    }
  };
  

  function handleSubmit() {
    //check for bad words
    if (checkBadWords(legal)) {
      alert("Please remove bad words.");
    } else if (!isJsonString(JSON.stringify(body))) {
       console.log('There is a problem with the input text.');
    } else {
      setDisplayLoader(true);
      fetch("https://c6ch9z3aq4.execute-api.us-east-1.amazonaws.com/dev/lawyerish", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      })
      .then(response => response.json())
      .then(data => {
        const latestSubmission = {
          responseId: JSON.parse(data.body).id,
          promptTokens: JSON.parse(data.body).usage.prompt_tokens,
          completionTokens: JSON.parse(data.body).usage.completion_tokens,
          promptWords: countWordsInText(legal),
          completionWords: countWordsInText(JSON.parse(data.body).message),
          contentFilter: parseInt(JSON.parse(data.body).filter_output),
        };
        //console.log('Data: ',data);
        handleSaveHistory(data);
        setAnswerResponseId(latestSubmission.responseId);
        updateUserStats(latestSubmission); 
        setAnswer(removeStartingLineBreaks(JSON.parse(data.body).message));
        setPromptTokens(JSON.parse(data.body).usage.prompt_tokens);
        setCompletionTokens(JSON.parse(data.body).usage.completion_tokens);
        setTotalTokens(JSON.parse(data.body).usage.total_tokens);
      })
      .catch(error => console.error(error))
      .finally(() => {setDisplayLoader(false);})
    }
  };

  function clearTextAreas() {
    setLegal('');
    setAnswer('');
  };

  function removeStartingLineBreaks(text) {
    return text.replace(/^(\n)+/, '');
  };

  return (
    <div>
    {route !== 'authenticated' ? (
      <div>
        <Text>Loading</Text>
      </div>
    ) : (
      <div>
      <Container sx={{backgroundColor: '#DEE2E6'}} mt="sm" mb="lg">
        <Container>
          <Title align="center" className={classes.title}>English, not Lawyerish!</Title>
          <Text align="center">Convert your complex legal text into something a seven year old can understand!</Text>
        </Container>
        <br></br>
        <Container>
          <Grid>
            <Grid.Col md={6}>
              <Box>
                <Grid>
                  <Grid.Col md={12}>
                    <Textarea
                    placeholder="Enter your legal text"
                    label="Legal text"
                    value={legal}
                    onChange={(e) => setLegal(e.currentTarget.value)}
                    rightSection={<Image 
                                      src={cross} 
                                      sx={{alignSelf: 'start', margin: '7px', marginRight: '5px'}}
                                      onClick={() => clearTextAreas()}
                                      height={15}
                                      width={15}
                                      >
                                    </Image>}
                    autosize
                    minRows={10}
                    />
                  </Grid.Col>
                  <Grid.Col md={6}>
                    <Text className={classes.text}>{countWordsInText(legal)}/750 words</Text>
                  </Grid.Col>
                  <Grid.Col md={6} sx={{textAlign: "end"}}>
                    <Button 
                      onClick={() => {handleSubmit()}}
                      disabled={(wordsRemaining < (countWordsInText(legal) * 1.5)) ? true : false}
                      >Submit</Button>
                  </Grid.Col>
                </Grid>
              </Box>
            </Grid.Col>
            <Grid.Col md={6}>
              <Box>
                <Grid>
                  <Grid.Col md={12}>
                    <Textarea readOnly
                    label="An explanation for a second grader"
                    minRows={10}
                    value={answer}
                    />
                  </Grid.Col>
                  <Grid.Col sm={12}>
                    <Group position='apart'>
                      <Text className={classes.text}>
                        {countWordsInText(answer)}{' words'}
                        {/*countWordsInText(legal) + countWordsInText(answer)}{' total words'*/}
                      </Text>
                      {answer ? <Feedback userid={userid} answerResponseId={answerResponseId}/> : null}
                    </Group>
                  </Grid.Col>
                </Grid>
              </Box>
             </Grid.Col>
             <Grid.Col xs={12}>
              <Center>
                {displayLoader ? <Loader /> : null}
              </Center>
             </Grid.Col>
          </Grid>
          <Container mt={20} mb={10}>
            <Center>
              <Image 
                src={lightbulb}
                className={classes.iconImage} 
                onClick={() => {setDisplayExample(displayExample => !displayExample)}} 
                caption="Examples" 
              />
              <Image 
                src={clockImage} 
                className={classes.iconImage} 
                onClick={() => {setDisplayHistory(displayHistory => !displayHistory)}}
                caption="History" 
              />
              <Image 
                src={info} 
                className={classes.iconImage} 
                onClick={() => {setDisplayTipsModal(displayTipsModal => !displayTipsModal)}}
                caption="Tips" 
              />
            </Center>
          </Container>
        </Container>
        <br></br>
        {displayExample ? <SampleLegalLingo /> : null}
        {displayHistory ? <ShowHistory historyTable={historyTable} /> : null}
        {displayTipsModal ? <TipsModal /> : null}
        {/* <CardWithStats stats={userStatsTable} /> */}
        {/* <Button onClick={async () => {await DataStore.clear()}}>Clear Local Datastore</Button> */}
        <br></br>
      <Text align='center' size='xs'>
        Note: AI generated summaries may contain some inaccuracies. Please consult a
        professional before acting upon them.
      </Text>
      </Container>
      <Text align="center">&copy; 2022 Not Lawyerish, Inc.</Text>
    </div>

    )}
  </div>
  );
};

export default MainPage;
