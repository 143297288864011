import { Card, Image, createStyles, Group, Text } from "@mantine/core";
import image from './train_moving.jpg';

const useStyles = createStyles((theme) => ({
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
  
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: `${theme.spacing.sm}px ${theme.spacing.lg}px`,
      borderTop: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
      }`,
    },
  
    title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      lineHeight: 1,
    },
  }));

export function CardWithStats({ stats }) {
    const { classes } = useStyles();
    console.log("Stats", stats)
  return (
    <Card withBorder p="lg" className={classes.card}>
        <Card.Section>
            <Image src={image} height={150} width={200} />
        </Card.Section>

        <Group position="apart" mt="lg">
            <Text size="sm" weight={700} className={classes.title}>
                Your usage statistics
            </Text>
        </Group>
        <Text mt="sm" mb="md" color="dimmed" size="xs">
        Cumulative Prompt Tokens: {stats.cumulativePromptTokens}
        <br />
        Cumulative Completion Tokens: {stats.cumulativeCompletionTokens}
        <br />
        Cumulative Prompt Words: {stats.cumulativePromptWords}
        <br />
        Cumulative Completion Words: {stats.cumulativeCompletionWords}
        <br />
        Words Remaining: {stats.wordsRemaining}
        </Text>

    </Card>
    );
};