import React from "react";
import { Text, Container, Box, Divider } from "@mantine/core";

export function SampleLegalLingo() {
    return(
    <Container mt="sm" sx={{backgroundColor: '#BAC8FF'}}>
        <Box>
            <h4>Try some examples</h4>
            <Text>The following language shall be included in contracts for City
            projects between the Consultant and any Subcontractors, vendors, 
            and suppliers: Contractor shall not discriminate on the basis of 
            race, gender, gender expression, gender identity, religion, 
            national origin, ethnicity, sexual orientation, age, or disability
            in the solicitation, selection, hiring, or treatment of 
            subcontractors, vendors, or suppliers. Consultant shall 
            provide equal opportunity for Subcontractors to participate
            in opportunities. Consultant understands and agrees that violation 
            of this clause shall be considered a material breach of the 
            contract and may result in contract termination, debarment, 
            or other sanctions.
            </Text>
            <Divider my="sm" />
            <Text>
                No amendment or other modification of this Agreement or 
                waiver of any term hereof shall be effective unless in 
                writing and signed by both parties.
            </Text>
            <Divider my="sm" />
            <Text>This Agreement is the entire, final, complete, and 
                fully integrated agreement between the Parties with 
                respect to the subject matter hereof and supersedes 
                any prior agreements or communications between the 
                Parties, whether written, oral, electronic 
                or otherwise.
            </Text>
            <Divider my="sm" />
            <Text>
                If any term or provision of this Agreement shall, 
                to any extent, be determined to be invalid or 
                unenforceable by a court or body of competent 
                jurisdiction, the remainder of this Agreement 
                shall not be affected thereby, and each term 
                and provision of this Agreement shall be valid 
                and enforceable to the fullest extent 
                permitted by law.
            </Text>
            <Divider my="sm" />
            <Text>
                Material Adverse Effect. Between December 31, 2002 
                and the Closing Date: (a) no Credit Party has 
                incurred any obligations, contingent or noncontingent 
                liabilities, liabilities for Charges, long-term 
                leases or unusual forward or long-term commitments 
                that are not reflected in the Pro Forma and that, 
                alone or in the aggregate, could reasonably be 
                expected to have a Material Adverse Effect, 
                (b) no contract, lease or other agreement or 
                instrument has been entered into by any 
                Credit Party or has become binding upon any 
                Credit Party's assets and no law or regulation 
                applicable to any Credit Party has been adopted 
                that has had or could reasonably be expected to 
                have a Material Adverse Effect, and (c) no 
                Credit Party is in default and to the best of 
                Borrower's knowledge no third party is in 
                default under any material contract, lease 
                or other agreement or instrument, that alone 
                or in the aggregate could reasonably be expected 
                to have a Material Adverse Effect. Between 
                December 31, 2002 and the Closing Date no event 
                has occurred, that alone or together with other 
                events, could reasonably be expected to have a 
                Material Adverse Effect.
            </Text>
            <Divider my="sm" />
            <Text>
                The parties are cognizant that John has significant 
                assets as reflected in Schedule “A” annexed hereto. 
                It is the parties’ express intention and desire for 
                this agreement to secure not only the pre-marital and 
                separate property rights of John, but also to preserve, 
                shield and protect the beneficial interest which John’s 
                children have in their father’s estate. Inasmuch as John 
                has been previously divorced, both John and Jane desire 
                to enter into a contractual agreement which is intended 
                to govern their financial affairs and obligations to one 
                another in the event of a dissolution of their marital 
                relationship, said agreement being a deliberate and 
                calculated attempt by John and Jane to avoid a painful 
                and costly litigation process.
            </Text>
            <Divider my="sm" />
            <Text>
                Except as expressly provided in this agreement to the contrary, 
                each party desires that all property owned by him or her at the 
                date of the parties̓ marriage together with any appreciation or 
                increase thereon shall be free from any claim of the other that 
                may arise by reason of their contemplated marriage, and that in 
                the event of a termination event as hereinafter set forth, all 
                such property shall be his or her respective separate property 
                and shall not be subject to any equitable distribution or 
                community property laws in the event that the parties establish a
                domicile or residence in a state that has adopted either of such systems.
            </Text>
            <Divider my="sm" />
            <Text>
                Except as otherwise provided in the within agreement, neither party 
                shall by virtue of the marriage have or acquire any right, title or 
                claim in or to the other party's real or personal property upon the 
                other party's death. In the event of the death of either party, that 
                party's estate shall descend to, or vest in his or her heirs at law, 
                distributees, legatees or devisees and in such a manner as may be 
                prescribed by his or her Last Will and Testament or Codicil thereto, 
                or in default thereof, by the statutory law then in force, as though 
                no marriage between the parties had ever taken place.
            </Text>
            <Divider my="sm" />
            <Text>
                The parties’ rights hereunder are cumulative and not alternative. 
                Neither the failure nor any delay by any party in exercising any 
                right, power, or privilege hereunder will operate as a waiver of 
                such right, power, or privilege. No single or partial exercise of 
                any such right, power, or privilege will preclude any other or 
                further exercise of such right, power, or privilege or the exercise 
                of any other right, power, or privilege. To the maximum extent 
                permitted by applicable law: (i) no claim or right arising out 
                of this Agreement can be discharged by a party, in whole or in part, 
                by a waiver or renunciation of the claim or right, unless in writing 
                signed by the other party; (ii) no waiver that may be given by a 
                party will be applicable except in the specific instance for which 
                it is given; and (iii) no notice to or demand on one party will be 
                deemed to be a waiver of any obligation of such party or of the right 
                of the party giving such notice or demand to take further action 
                without notice or demand as provided herein. The Employee will not 
                assert that the Company’s failure, refusal to enforce, or delay in 
                enforcing any agreement containing obligations identical or similar 
                to those contained in this Agreement against any of its other 
                employees or former employees constitutes a waiver of the Company’s 
                rights hereunder.
            </Text>
            <Divider my="sm" />
        </Box>
    </Container>
    )
};