export const createHistory = /* GraphQL */ `
  mutation CreateHistory(
    $input: CreateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    createHistory(input: $input, condition: $condition) {
      id
      userId
      legal_text
      promptTokens
      completionTokens
      promptWords
      completionWords
      plain_english
      contentFilter
    }
  }
`;

export const createUserStats = /* GraphQL */ `
  mutation CreateUserStats(
    $input: CreateUserStatsInput!
    $condition: ModelUserStatsConditionInput
  ) {
    createUserStats(input: $input, condition: $condition) {
      id
      userId
      cumulativePromptTokens
      cumulativeCompletionTokens
      cumulativePromptWords
      cumulativeCompletionWords
      wordsPurchased
      amountPaid
      wordsRemaining
      contentFilterOne
      contentFilterTwo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;

export const createFeedbackNotLawyerish = /* GraphQL */ `
  mutation CreateFeedbackNotLawyerish(
    $input: CreateFeedbackNotLawyerishInput!
    $condition: ModelFeedbackNotLawyerishConditionInput
  ) {
    createFeedbackNotLawyerish(input: $input, condition: $condition) {
      historyId
      userId
      feedback_number
      feedback_text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;