import { useEffect } from "react";
import {
    Authenticator,
    useAuthenticator,
    Flex,
    Grid,
    Image,
    useTheme,
    View,
    Text,
    Link,
    Heading,
  } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import { useNavigate, useLocation } from 'react-router';
  

function Footer() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" padding={tokens.space.medium}>
      <Text>&copy; All Rights Reserved</Text>
    </Flex>
  );
};

function Header() {
    const { tokens } = useTheme();
  
    return (
      <Flex justifyContent="center">
        {/*<Image
          alt="logo"
          src="https://docs.amplify.aws/assets/logo-dark.svg"
          padding={tokens.space.medium}
          />*/}
      </Flex>
    );
  };

function SignInFooter() {
    const { toResetPassword } = useAuthenticator();
    const { tokens } = useTheme();
  
    return (
      <Flex justifyContent="center" padding={`0 0 ${tokens.space.medium}`}>
        <Link onClick={toResetPassword}>Reset your password</Link>
      </Flex>
    );
  };

function SignInHeader() {
    const { tokens } = useTheme();

    return (
        <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
        Sign in to your Account
        </Heading>
    );
};
  
  const components = {
    Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    Footer
  };
  
  export function Login() {
    const { tokens } = useTheme();
    const { route } = useAuthenticator(context => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    let from = location.state?.from?.pathname || '/';
    useEffect(() => {
      if (route === 'authenticated') {
        navigate('/main', { replace: true }); //was /main
      }
    }, [route, navigate, from]);
  
    return (
      <div>
        <Grid templateColumns={{ base: "1fr 0", medium: "1fr 1fr" }}>
            <Flex
            backgroundColor={tokens.colors.background.secondary}
            justifyContent="center"
            >
            <Authenticator components={components}>
                {({ signOut, user }) => (
                <main>
                    <h1>Hello {user.username}</h1>
                    <button onClick={signOut}>Sign out</button>
                </main>
                )}
            </Authenticator>
            </Flex>
            <View height="100vh">
            <Image
                src="https://images.unsplash.com/photo-1495954222046-2c427ecb546d?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=1600&ixid=MnwxfDB8MXxyYW5kb218MHx8Y29tcHV0ZXJzfHx8fHx8MTYzNzE5MzE2MQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=900"
                width="100%"
                height="100%"
                objectFit="cover"
            />
            </View>
        </Grid>
      </div>
    );
  };