export const historiesByUser = /* GraphQL */ `
  query HistoriesByUser(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    historiesByUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        legal_text
        promptTokens
        completionTokens
        promptWords
        completionWords
        plain_english
        createdAt
        contentFilter
        summaryNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;

export const statsByUser = /* GraphQL */ `
  query StatsByUser(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statsByUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        cumulativePromptTokens
        cumulativeCompletionTokens
        cumulativePromptWords
        cumulativeCompletionWords
        wordsPurchased
        amountPaid
        wordsRemaining
        contentFilterOne
        contentFilterTwo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;