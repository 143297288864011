import React from 'react';
import {
    createStyles,
    Image,
    Container,
    Title,
    Button,
    Group,
    Text,
    List,
    ThemeIcon,
  } from '@mantine/core';
  import { Link, useNavigate } from 'react-router-dom';

  import check from './check.png';
  import image from './start_image.svg';
  
  const useStyles = createStyles((theme) => ({
    inner: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: theme.spacing.xl * 1/2,
      paddingBottom: theme.spacing.xl * 4,
    },
  
    content: {
      maxWidth: 480,
      marginRight: theme.spacing.xl * 3,
  
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        marginRight: 0,
      },
    },
  
    title: {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontSize: 44,
      lineHeight: 1.5,
      fontWeight: 900,
      textAlign: 'center',
  
      [theme.fn.smallerThan('xs')]: {
        fontSize: 28,
      },
    },
  
    control: {
      [theme.fn.smallerThan('xs')]: {
        flex: 1,
      },
    },
  
    image: {
      flex: 1,
  
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },
  
    highlight: {
      //position: 'relative',
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      borderRadius: theme.radius.sm,
      padding: '4px 12px',
    },

    exampleText: {
      color: 'blue',
      '&:hover': {
        cursor: 'pointer',
      }
    },
  }));
  
  export function LandingPage() {
    const { classes } = useStyles();
    const navigate = useNavigate();
    return (
      <div>
        <Container>
          <div className={classes.inner}>
            <div className={classes.content}>
              <Title className={classes.title}>
                English, <br></br><span className={classes.highlight}>not lawyerish!</span>
              </Title>
              <Text color="dimmed" mt="md">
              Enpower yourself with artificial intelligence to summarize legal language 
              in a way that a seven year old can understand. See <Text className={classes.exampleText} onClick={() => navigate('/examples')}>our examples.</Text>
              </Text>
  
              <List
                mt={30}
                spacing="sm"
                size="sm"
                icon={
                  <ThemeIcon size={20} radius="xl">
                    <Image height={20} width={20} src={check} />
                  </ThemeIcon>}
              >
                <List.Item>
                  <b>Understand context</b> <span>&ndash;</span> Show off in front of 
                  your boss! Explain a complex legal construct in seconds
                </List.Item>
                <List.Item>
                  <b>Save time</b> <span>&ndash;</span> No more burning the midnight oil
                  or pulling your hair trying to decipher legalese
                </List.Item>
                <List.Item>
                  <b>Save money</b> <span>&ndash;</span> Avoid $500 per hour calls to your
                  lawyer just to understand what something means 
                </List.Item>
              </List>
  
              <Group mt={30}>
                <Link to="/login">
                  <Button radius="xl" size="md" className={classes.control}>
                    Get started
                  </Button>
                </Link>
              </Group>
            </div>
              <Image src={image} alt="girl working on a computer" className={classes.image} />
            </div>
        </Container>
      </div>
    );
  }

  export default LandingPage;