import { Container, Text } from '@mantine/core';
import faq from './faq.json';

function FAQ() {
    const faqItems = faq.map((item, index) => {
        return (
            <div className="faq-item" key={index}>
                <Text weight={500}>{item.question}</Text>
                <Text>{item.answer}</Text>
                <br />
            </div>
        );
    })

    return (
        <>
            <Container mt='xl'>
            <h1>FAQ</h1>
                {faqItems}
            </Container>
        </>
    );
};

export default FAQ;