//for testing
let user = "sidd-test-user";
var inputText = "There is some input text here.";

//remove line breaks
function removeLineBreaks(inputText) {
    return inputText.replace(/(\r\n|\n|\r)/gm, "");
};

//remove space at the end of the string
function removeSpaceAtEnd(inputText) {
    return inputText.replace(/\s+$/, "");
};

//remove space at the beginning of the string
function removeSpaceAtBeginning(inputText) {
    return inputText.replace(/^\s+/, "");
};

//prepare text for input into the api
export function prepareText(inputText) {
    return removeSpaceAtBeginning(removeSpaceAtEnd(removeLineBreaks(inputText)));
};

//check if the string is JSON
export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

//console.log(prepareText(inputText));
//console.log(isJsonString(JSON.stringify(req_body)));