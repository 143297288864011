/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserStats = /* GraphQL */ `
  query GetUserStats($id: ID!) {
    getUserStats(id: $id) {
      id
      userId
      cumulativePromptTokens
      cumulativeCompletionTokens
      cumulativePromptWords
      cumulativeCompletionWords
      wordsPurchased
      amountPaid
      wordsRemaining
      contentFilterOne
      contentFilterTwo
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listUserStats = /* GraphQL */ `
  query ListUserStats(
    $filter: ModelUserStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        cumulativePromptTokens
        cumulativeCompletionTokens
        cumulativePromptWords
        cumulativeCompletionWords
        wordsPurchased
        amountPaid
        wordsRemaining
        contentFilterOne
        contentFilterTwo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserStats = /* GraphQL */ `
  query SyncUserStats(
    $filter: ModelUserStatsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        cumulativePromptTokens
        cumulativeCompletionTokens
        cumulativePromptWords
        cumulativeCompletionWords
        wordsPurchased
        amountPaid
        wordsRemaining
        contentFilterOne
        contentFilterTwo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const statsByUser = /* GraphQL */ `
  query StatsByUser(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statsByUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        cumulativePromptTokens
        cumulativeCompletionTokens
        cumulativePromptWords
        cumulativeCompletionWords
        wordsPurchased
        amountPaid
        wordsRemaining
        contentFilterOne
        contentFilterTwo
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getHistory = /* GraphQL */ `
  query GetHistory($id: ID!) {
    getHistory(id: $id) {
      id
      userId
      legal_text
      promptTokens
      completionTokens
      promptWords
      completionWords
      plain_english
      createdAt
      contentFilter
      summaryNumber
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listHistories = /* GraphQL */ `
  query ListHistories(
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        legal_text
        promptTokens
        completionTokens
        promptWords
        completionWords
        plain_english
        createdAt
        contentFilter
        summaryNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncHistories = /* GraphQL */ `
  query SyncHistories(
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        legal_text
        promptTokens
        completionTokens
        promptWords
        completionWords
        plain_english
        createdAt
        contentFilter
        summaryNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const historiesByUser = /* GraphQL */ `
  query HistoriesByUser(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    historiesByUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        legal_text
        promptTokens
        completionTokens
        promptWords
        completionWords
        plain_english
        createdAt
        contentFilter
        summaryNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const getFeedbackNotLawyerish = /* GraphQL */ `
  query GetFeedbackNotLawyerish($historyId: ID!, $createdAt: AWSDateTime!) {
    getFeedbackNotLawyerish(historyId: $historyId, createdAt: $createdAt) {
      historyId
      userId
      feedback_number
      feedback_text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
    }
  }
`;
export const listFeedbackNotLawyerishes = /* GraphQL */ `
  query ListFeedbackNotLawyerishes(
    $historyId: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelFeedbackNotLawyerishFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFeedbackNotLawyerishes(
      historyId: $historyId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        historyId
        userId
        feedback_number
        feedback_text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFeedbackNotLawyerishes = /* GraphQL */ `
  query SyncFeedbackNotLawyerishes(
    $filter: ModelFeedbackNotLawyerishFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFeedbackNotLawyerishes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        historyId
        userId
        feedback_number
        feedback_text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const feedbackByUser = /* GraphQL */ `
  query FeedbackByUser(
    $userId: ID!
    $createdAtFeedback_number: ModelFeedbackNotLawyerishFeedbackByUserCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackNotLawyerishFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedbackByUser(
      userId: $userId
      createdAtFeedback_number: $createdAtFeedback_number
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        historyId
        userId
        feedback_number
        feedback_text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
export const feedback_number_idx = /* GraphQL */ `
  query Feedback_number_idx(
    $feedback_number: Int!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFeedbackNotLawyerishFilterInput
    $limit: Int
    $nextToken: String
  ) {
    feedback_number_idx(
      feedback_number: $feedback_number
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        historyId
        userId
        feedback_number
        feedback_text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
      }
      nextToken
      startedAt
    }
  }
`;
