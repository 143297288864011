import React, { useState } from "react";
import { Modal, Button, Group, Image, 
    Text, Textarea, createStyles } from '@mantine/core';
import { API } from 'aws-amplify';
import { createFeedbackNotLawyerish } from '../mygraphql/mutations';
import ThumbUp from './ThumbUp.svg';
import thumb_down from './thumb_down.svg';

const useStyles = createStyles((theme) => ({
    
    feedbackImage: {
      maxWidth: 30,
      maxHeight: 30,
      marginRight: 10,
      '&:hover': {
        cursor: 'pointer',
        },
        '&:active': {
            maxWidth: 40,
        },
    },

    text: {
      fontSize: theme.fontSizes.sm - 1,
      '&:hover': {
        cursor: 'pointer',
        color: theme.colors.blue[5],
        },
    },
  }));

const Feedback = (props) => {
    const { classes } = useStyles();
    const [feedback, setFeedback] = useState("");
    const [feedbackNumber, setFeedbackNumber] = useState(0);
    const [modalOpened, setModalOpened] = useState(false);
    const [submitPressed, setSubmitPressed] = useState(false);

    async function saveFeedback() {
        let feedbackDetails = {
            historyId: props.answerResponseId,
            userId: props.userid,
            feedback_number: feedbackNumber,
            feedback_text: feedback,
        };
        //console.log('feedbackDetails: ', feedbackDetails);
        try {
          await API.graphql({
            query: createFeedbackNotLawyerish,
            variables: {input: feedbackDetails}
          });
          console.log('Feedback added successfully.');
        } catch (error) {
          console.log('Error saving post', error)
        }
      };
    
    const submitFeedback = () => {
        //save feedback to database
        saveFeedback();
        setSubmitPressed(true);
        setFeedback("");
        setFeedbackNumber(0);
        setTimeout(() => {
            setModalOpened(false);
            setSubmitPressed(false);
        }, 2000);
    };

    return (
        <>
        <Modal
          opened={modalOpened}
          onClose={() => setModalOpened(false)}
          title="Rate this summary"
        >
        {submitPressed ?
            <Text>Thank you for your feedback!</Text>
            :
            <div>
                <Group position="center">
                <Image
                    src={ThumbUp}
                    className={classes.feedbackImage}
                    onClick={() => setFeedbackNumber(1)}
                />
                <Image
                    src={thumb_down}
                    className={classes.feedbackImage}
                    onClick={() => setFeedbackNumber(-1)}
                />            
                </Group>
                <Textarea mb={10}
                    placeholder="Your feedback"
                    label="Description"
                    minRows={3}
                    value={feedback}
                    onChange={(event) => setFeedback(event.currentTarget.value)}
                />
                <Group position="center" mt="md">
                    <Button onClick={submitFeedback}>Submit</Button>
                </Group>
            </div>
        }
        </Modal>
        <Group position="right">
            <Text 
                className={classes.text}
                onClick={() => {setModalOpened(true); console.log("feedback button clicked.")}} 
                italic>
                Feedback
            </Text>
        </Group>
        </>
    );
}

export default Feedback;