import { Text, createStyles, Container, Grid } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'inline-flex',
    //justifyItems: 'space-between',
  },
  
  text: {
    fontSize: theme.fontSizes.sm - 1,
  }
}));

function TokenCount({ promptWords, completionWords, totalWords }) {
  const {classes} = useStyles();

  return (
    <Container className={classes.container}>
      <Grid>
        <Grid.Col span={4}>
      <Text className={classes.text}>Words in legal text: {promptWords}</Text>
      </Grid.Col>
      <Grid.Col span={4}>
      <Text className={classes.text}>Words in response: {completionWords}</Text>
      </Grid.Col>
      <Grid.Col span={4}>
      <Text className={classes.text}>Total words: {totalWords}</Text>
      </Grid.Col>
      </Grid>
    </Container>
  );
};

export default TokenCount;