import { useState } from 'react';
import { Modal, Group, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    text: {
        fontSize: theme.fontSizes.sm - 1,
      },
}));

export function TipsModal() {
    const [opened, setOpened] = useState(true);
    const { classes } = useStyles();

    return (
        <Modal
        title="Tips"
        opened = {opened}
        onClose={() => setOpened(false)}
        >
        <Group position="left">
            <div>
                <p className={classes.text}>1. To focus on one paragraph, input just that paragraph.</p>
                <p className={classes.text}>2. Multiple input paragraphs will be concisely summarized into one paragraph.</p>
                <p className={classes.text}>3. Remove expletives.</p>
                <p className={classes.text}>4. Break down long texts into shorter modules. You can later combine the results of the modules to produce an even more concise summary. Even a legal textbook can be summarized in this way.</p>
                <p className={classes.text}>5. If you do not see any response at all, please send an email to saran@notlawyerish.com.</p>
            </div>
        </Group>
        </Modal>
    );
};