import { useState } from "react";
import { createStyles, Table, ScrollArea, Text } from '@mantine/core';


const useStyles = createStyles((theme) => ({
    header: {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
        transition: 'box-shadow 150ms ease',
    
        '&::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          borderBottom: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[2]
          }`,
        },
      },

      time: {
        fontSize: 'xs',
      },
    
      scrolled: {
        boxShadow: theme.shadows.sm,
      },
    }));


function ShowHistory({ historyTable }) {
    const { classes, cx } = useStyles();
    const [scrolled, setScrolled] = useState(false);

    function displayDateInLongFormat(dt) {
      /*
      if (Object.prototype.toString.call(dt) !== '[object Date]') {
        return "";
      };*/
      var date = new Date(dt);
      const formattedDate =  date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      const formattedTime = date.toLocaleTimeString();
      return (
        <Text>Created on: {formattedDate}; {formattedTime}</Text>
      );
  };

    const rows = historyTable.map((row) => (
      <tr key={row.id}>
        <td>
          {row.legal_text}
          <br />
          <br />
          {displayDateInLongFormat(row.createdAt)}
        </td>
        <td>{row.plain_english}</td>
      </tr>
    ));
    
    return(
        <ScrollArea sx={{ height: 300 }} onScrollPositionChange={({ y }) => setScrolled(y !== 0)}>
        <Table striped>
          <thead className={cx(classes.header, { [classes.scrolled]: scrolled })}>
            <tr>
              <th>Legal Text</th>
              <th>Plain English</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
    );
};

export default ShowHistory;