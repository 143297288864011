import React, { useState, useEffect } from 'react';
import {
  createStyles,
  Header,
  Container,
  Group,
  Button,
  Burger,
  Image,
  Transition,
  Paper,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
//import { DataStore } from '@aws-amplify/datastore';
import StatsInMenu from './StatsInMenuComponent';
import logo from './Not_lawyerish.png';

const HEADER_HEIGHT = 60;

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  linkLabel: {
    marginRight: 5,
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 0,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
}));

const Logo = () => {
  return(
    <div style={{ maxWidth: 56 }}>
      <Image src={logo} fit="contain"/>
    </div>
  )};

export function Layout() {
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const { user }= useAuthenticator(context => [context.user]);
  const [username, setUsername] = useState('')
  const [userid, setUserid] = useState('');
  const navigate = useNavigate();

  function logOut() {
    signOut();
    //DataStore.clear();
    navigate('/');
  };

  useEffect(() => {
    async function getUserInfo() {
    const user = await Auth.currentAuthenticatedUser();
    //console.log('name: ', user.attributes.name);
    //console.log('userid: ', user.username);
    setUsername(user.attributes.name);
    setUserid(user.username);
    };
    getUserInfo();
  }, [route]);

  const { classes } = useStyles();
  const [opened, { toggle, close }] = useDisclosure(false);
    
  return (
    <>
    <Header height={HEADER_HEIGHT} sx={{ borderBottom: 0 }} mb="sm">
      <Container className={classes.inner} fluid>
        <Group>
          <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />
          <Transition transition="pop-top-left" duration={200} mounted={opened}>
            {(styles) => (
              <Paper className={classes.dropdown} withBorder style={styles}>
              <span onClick={() => navigate('/landing')} className={classes.link}>Landing Page</span>
              <span onClick={() => navigate('/main')} className={classes.link}>Main</span>
              <span onClick={() => navigate('/faq')} className={classes.link}>FAQ</span>
              <span onClick={() => navigate('/examples')} className={classes.link}>Examples</span>
              </Paper>
            )}
          </Transition>
        <Logo />
        </Group>
        <Group spacing={5} className={classes.links}>
            <span onClick={() => {navigate('/landing'); close()}} className={classes.link}>Landing Page</span>
            <span onClick={() => {navigate('/main'); close()}} className={classes.link}>Main</span>
            <span onClick={() => {navigate('/faq'); close()}} className={classes.link}>FAQ</span>
            <span onClick={() => {navigate('/examples'); close()}} className={classes.link}>Examples</span>
        </Group>
        <span>
          {route !== 'authenticated' ? (
            <Button onClick={() => navigate('/login')} radius="xl" sx={{ height: 30 }}>
            Login
            </Button>
            ) : (
            (<div>
              {username ? 
                <StatsInMenu user={user} userid={userid} username={username} />
                : <span></span>}
            </div>))
          }
        </span>
      </Container>
    </Header>
    <Outlet />
    </>
  );
};